<template>
  <Teleport to="#main">
    <div
      v-if="isVisible"
      class="bottom-0 z-50 w-full transition-opacity duration-200 ease-in-out"
      :class="[
        {
          fixed: !isFooterVisible,
          absolute: isFooterVisible,
          'opacity-0': !isFullyVisible,
          'opacity-100': isFullyVisible,
        },
      ]"
    >
      <div class="container-spacing-horizontal container relative">
        <slot :css-classes />
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { useIntersectionObserver } from '@vueuse/core'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'

const footer = ref<HTMLElement | null>(null)
const isFooterVisible = ref(false)
const isVisible = ref(false)
const isFullyVisible = ref(false)

onMounted(() => {
  footer.value = document.querySelector('footer')
  setTimeout(() => {
    isVisible.value = true
    setTimeout(() => {
      isFullyVisible.value = true
    }, 50)
  }, 200)
})

const { stop } = useIntersectionObserver(
  footer,
  ([{ isIntersecting }]) => {
    isFooterVisible.value = isIntersecting
  },
  { threshold: 0 },
)

const cssClasses = computed(
  () =>
    'absolute bottom-4 right-4 md:bottom-8 md:right-8 transition-transform duration-200 ease-in-out hover:scale-105 !rounded-full',
)

onBeforeUnmount(() => {
  stop()
  isVisible.value = false
  isFullyVisible.value = false
})

defineOptions({
  name: 'ButtonFab',
})
</script>
